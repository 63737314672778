<template>
    <div>
        <div style="text-align:center;width: 100%;height:3rem;position: relative" @click="useScan">
            <van-icon size="40" name="scan" />
        </div>
    </div>
</template>

<script>
    import wx from 'weixin-js-sdk'
    // import config from "./config";
    export default {
        name: "scan",
        created(){
            let _this=this;

            let url = window.location.href;
            this.config({url, jsApiList: ['scanQRCode']},()=>{
                _this.useScan();
            })
        },
        methods:{
            useScan(){
                let _this=this
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        _this.getScan(result);
                    }
                });
            },
            getScan(result){
                this.$axios.post('wechat/integralPay',{
                    QR:result,

                }).then((res)=>{
                    if(res.data.code==='0000')
                    {
                        Toast.success('兑换成功');
                    }else{
                        Toast.fail(res.data.content)
                    }
                })
            },
            config({url,jsApiList},callback) {
                this.$axios.post('wechatJsConfig', {
                    "apis": jsApiList,
                    url
                },{
                    headers:{
                        Authorization:localStorage.getItem('token')
                    }
                }).then((res) => {
                    if (res.data.code === '0000') {
                        let configs = JSON.parse(res.data.content.config);
                        // that.$router.push('detail')
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: configs.appId, // 必填，公众号的唯一标识
                            timestamp: configs.timestamp, // 必填，生成签名的时间戳
                            nonceStr: configs.nonceStr, // 必填，生成签名的随机串
                            signature: configs.signature,// 必填，签名
                            jsApiList // 必填，需要使用的JS接口列表
                        });
                        //通过ready接口处理成功验证
                        wx.ready(function () {
                            callback?callback():'';
                        })
                        wx.error(function (err) {
                            console.log(err)
                            // alert(JSON.stringify(err))
                            throw new Error(err)
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
